import React, { useEffect } from "react";

// img

import sboard1 from "./../assets/portfolio/sboard1-min.jpg";
import sboard2 from "./../assets/portfolio/sboard2-min.jpg";
import sboard3 from "./../assets/portfolio/sboard3-min.jpg";
import sboard4 from "./../assets/portfolio/sboard4-min.jpg";
import sboard5 from "./../assets/portfolio/sboard5-min.jpg";
import sboard6 from "./../assets/portfolio/sboard6-min.jpg";
import sboard8 from "./../assets/portfolio/sboard8-min.jpg";
import sboard9 from "./../assets/portfolio/sboard9-min.jpg";
import sboard10 from "./../assets/portfolio/sboard10-min.jpg";
import sboard11 from "./../assets/portfolio/sboard11-min.jpg";

const StoryBoard = ({ setFullScreen, setPicSrc }) => {
	const handleFullScreenPic = (e) => {
		setFullScreen(true);
		setPicSrc(e.target.src);
	};

	useEffect(() => {
		const gridItems = document.querySelectorAll(".grid-item");
		for (let i = 0; i < gridItems.length; i++) {
			gridItems[i].style.animationDelay = `${450 + 50 * i * Math.floor(Math.random() * 3)}ms`;
		}
	});

	return (
		<>
            <div className="div1 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard1} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div2 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard2} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div3 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard3} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div4 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard4} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div5 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard5} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div6 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard6} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div7 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard8} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div8 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard9} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div9 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard10} alt="illustration" className="portoflio-illustration" />
            </div>
            <div className="div10 grid-item" onClick={(e) => handleFullScreenPic(e)}>
                <img src={sboard11} alt="illustration" className="portoflio-illustration" />
            </div>
		</>
	);
};

export default StoryBoard;
