import React, { useState } from "react";

import TitleUnderline from "../components/TitleUnderline";
import FullScreenPicture from "../components/FullScreenPicture";
import Grid from "../components/Grid";
import StoryBoard from "../components/StoryBoard"
import MotionDesign from "../components/MotionDesign"


const Portfolio = () => {
	const [fullScreen, setFullScreen] = useState(false);
	const [picSrc, setPicSrc] = useState("");
	const [activeSection, setActiveSection] = useState("illustration")
    const [portfolioDisplayed, setPortfolioDisplayed] = useState(false);

	document.addEventListener("scroll", () => {
		if (window.scrollY >= 100) setPortfolioDisplayed(true) 
	})

	const handleSlideRight = () => {
		document.getElementById("portfolioGrid").scrollLeft += window.innerWidth;
	};
	const handleSlideLeft = () => {
		document.getElementById("portfolioGrid").scrollLeft -= window.innerWidth;
	};
	return (
		<div className="home-page__section portfolio-section" id="portfolioSection">
			{fullScreen && <FullScreenPicture picSrc={picSrc} setFullScreen={setFullScreen} />}
			{portfolioDisplayed && <h2>Portfolio</h2>}
			{portfolioDisplayed && <TitleUnderline />}
			<div className="portfolio-section__content">
				{portfolioDisplayed && (
					<ul className="grid-nav">
						<li className={activeSection === "illustration" ? "active" : ""} onClick={() => setActiveSection("illustration")}>Illustration</li>
						<li className={activeSection === "storyboard" ? "active" : ""} onClick={() => setActiveSection("storyboard")}>StoryBoard</li>
						<li className={activeSection === "motiondesign" ? "active" : ""} onClick={() => setActiveSection("motiondesign")}>Motion Design</li>
					</ul>
				)}
				<div className="portfolio-section__content--grid" id="portfolioGrid">
					<div className={`portfolio-grid ${activeSection + '-portfolio-grid'}`}>
						{portfolioDisplayed && activeSection === "illustration" && <Grid setFullScreen={setFullScreen} setPicSrc={setPicSrc} />}
						{portfolioDisplayed && activeSection === "storyboard" && <StoryBoard setFullScreen={setFullScreen} setPicSrc={setPicSrc} />}
						{portfolioDisplayed && activeSection === "motiondesign" && <MotionDesign setFullScreen={setFullScreen} setPicSrc={setPicSrc} />}
					</div>
				</div>
				{portfolioDisplayed && activeSection === "illustration" && (
					<button className="slide-button slide-right" type="button" onClick={handleSlideRight}>
						&#8641;
					</button>
				)}
				{portfolioDisplayed && activeSection === "illustration" && (
					<button className="slide-button slide-left" type="button" onClick={handleSlideLeft}>
						&#8637;
					</button>
				)}
			</div>
		</div>
	);
};

export default Portfolio;
