import React, { useEffect } from "react";

// img

import mdesign1 from "./../assets/portfolio/mdesign1-min.jpg";
import mdesign2 from "./../assets/portfolio/mdesign2-min.jpg";

const MotionDesign = ({ setFullScreen, setPicSrc }) => {

	useEffect(() => {
		const gridItems = document.querySelectorAll(".grid-item");
		for (let i = 0; i < gridItems.length; i++) {
			gridItems[i].style.animationDelay = `${450 + 50 * i * Math.floor(Math.random() * 3)}ms`;
		}
	});

	return (
		<>
			<a className="grid-item item-33" href="https://www.youtube.com/watch?v=p4NV1mwfMaM" target="_blank" rel="noopener noreferrer" >
				<img src={mdesign1} alt="illustration" className="portoflio-illustration" />
			</a>
			<a className="grid-item item-34" href="https://www.youtube.com/watch?v=YDh1brxq66U" target="_blank" rel="noopener noreferrer">
				<img src={mdesign2} alt="illustration" className="portoflio-illustration" />
			</a>
		</>
	);
};

export default MotionDesign;
